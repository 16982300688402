import { PUBLIC_SENTRY_DSN, PUBLIC_SENTRY_ENVIRONMENT, PUBLIC_COMMIT_REF } from '$env/static/public'
import { dev } from '$app/environment'
import * as Sentry from '@sentry/svelte'
import LogRocket from 'logrocket'

export const basicSentryConfig = {
    dsn: PUBLIC_SENTRY_DSN,
    environment: PUBLIC_SENTRY_ENVIRONMENT || dev ? 'development' : 'production',
    tracesSampleRate: 1.0,
}

if (PUBLIC_COMMIT_REF !== undefined) {
    basicSentryConfig.release = PUBLIC_COMMIT_REF
}

/**
 * Identify the user for our error trackers.
 *
 * @param username
 */
export const logUsername = username => {
    Sentry.setUser({ username })
    LogRocket.identify(username)
}

/**
 * Capture an error message. This is treated like an error in our error trackers and will be reported (if issue is not
 * already ignored)
 *
 * @param {string} message
 * @param {object} extraData
 */
export const captureMessage = (message, extraData) => {
    Sentry.captureMessage(message, {
        contexts: extraData
            ? {
                  captureMessageExtraContext: extraData,
              }
            : {},
    })
    LogRocket.captureMessage(message, {
        extra: extraData,
    })
}

/**
 * Log a message. Kinda like console.log, but is seen only in our error trackers, and not in browser console. This is
 * not treated like an error and will not cause separate alerts.
 *
 * @param {import('$lib/types').ErrorLogParams} params
 */
export const log = (params = { message: '' }) => {
    Sentry.addBreadcrumb({
        ...params,
    })
    LogRocket.log(params.message)
}
